<template>
  <article
    :class="{
      'e-dialog__item--ingoing': !data.outgoing,
      'e-dialog__item--outgoing': data.outgoing,
      'e-dialog__item--open': itemOpen
    }"
    class="e-dialog__item"
  >
    <div class="e-dialog__wrapper">
      <div v-if="messageEditorOpen" class="e-dialog__overlay" />

      <main class="e-dialog__body">
        <div class="e-dialog__content" v-html="data.body" />
      </main>

      <footer class="e-dialog__footer">
        <!-- Delete -->
        <el-button
          v-if="data.outgoing"
          class="e-btn--delete e-btn--ghost"
          @click="deleteItem"
        >
          <IconTrash />
          <span class="e-mobile-hidden">
            {{ $t('system.delete') }}
          </span>
        </el-button>
        <!-- /Delete -->

        <el-button-group v-if="!data.outgoing && editorEnabled">
          <el-button
            type="secondary"
            class="e-btn--answer"
            @click="openAnswerField"
          >
            <span class="e-mobile-hidden">
              {{ $t('system.toThisMessage') }}
            </span>
            {{ $t('system.answer') }}
            <IconReply />
          </el-button>

          <el-dropdown
            trigger="click"
            class="el-button el-button--secondary e-btn--answer-context"
            @command="openQuickReply"
          >
            <span class="el-dropdown-link">
              <IconContext />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="done">
                {{ $t('messages.quickReply.doneTitle') }}
              </el-dropdown-item>
              <el-dropdown-item command="later">
                {{ $t('messages.quickReply.laterTitle') }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-button-group>
      </footer>
    </div>

    <!-- Answer Area -->
    <el-collapse
      v-if="editorIsVisible"
      v-model="collapseAnswer"
      class="e-dialog__answer"
    >
      <el-collapse-item name="1">
        <MessageEditor :edit-message="editMessage" class="hide-header" />
      </el-collapse-item>
    </el-collapse>
  </article>
</template>

<script>
import api from 'api'
import { mapActions, mapGetters } from 'vuex'
import MessageEditor from 'organisms/MessageEditor/MessageEditor'

// Icons
import IconReply from 'assets/icons/reply.svg'
import IconContext from 'assets/icons/context.svg'
import IconTrash from 'assets/icons/trashcan.svg'

export default {
  name: 'DialogItemBody',
  components: {
    IconReply,
    IconContext,
    IconTrash,
    MessageEditor
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    itemOpen: {
      type: Boolean,
      default: false
    },
    rootId: {
      type: String,
      default: ''
    },
    editorEnabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      collapseAnswer: []
    }
  },
  computed: {
    ...mapGetters(['editMessage', 'confirmOpen', 'messageEditorOpen']),
    editorIsVisible() {
      return (
        this.editorEnabled &&
        !this.data.outgoing &&
        this.itemOpen &&
        this.messageEditorOpen
      )
    }
  },
  watch: {
    messageEditorOpen: function(isOpen) {
      if (!isOpen && this.$route.name === 'dialog') {
        if (this.editMessageChanged && this.editorEnabled) {
          this.setConfirmOpen(true)
          this.setMessageEditorOpen(true)
          return
        }
        this.closeAnswerField()
      }
    },
    editorEnabled: function(isEnabled) {
      if (!isEnabled) {
        this.setMessageEditorOpen(false)
        this.setConfirmOpen(false)
        this.closeAnswerField()
      }
    },
    confirmOpen: function(isOpen) {
      if (!isOpen && this.$route.name === 'dialog') {
        this.getDialog(this.rootId)
      }
    },
    itemOpen: function(newVal) {
      if (newVal === true && this.editMessage.body !== '') {
        this.openAnswerField()
      }
    }
  },

  mounted() {
    if (this.itemOpen && this.editMessage.body !== '') {
      this.openAnswerField()
    }
  },

  methods: {
    ...mapActions([
      'getDialog',
      'getDialogs',
      'getSentMessages',
      'removeDialogItem',
      'resetEditMessages',
      'setMessageEditorOpen',
      'setConfirmOpen'
    ]),
    toggleMessage() {
      this.itemOpen = !this.itemOpen
    },
    openAnswerField() {
      if (!this.editorEnabled) {
        return
      }
      if (!this.editMessage.rootId) {
        const msg = {
          subject: this.data.subject,
          parentId: this.data.id,
          rootId: this.data.rootId,
          body: ''
        }
        this.resetEditMessages(msg)
      }
      this.setMessageEditorOpen(true)
      this.collapseAnswer = ['1']
    },
    closeAnswerField() {
      this.collapseAnswer = []
    },
    openQuickReply(command) {
      const msg = {
        subject: this.data.subject,
        parentId: this.data.id,
        rootId: this.data.rootId,
        body: ''
      }
      switch (command) {
        case 'done':
          msg.body = this.$t('messages.quickReply.done')
          break
        case 'later':
          msg.body = this.$t('messages.quickReply.later')
          break
      }
      this.resetEditMessages(msg)

      this.$nextTick(() => {
        this.openAnswerField()
      })
    },
    deleteItem() {
      this.$confirm(
        this.$t('messages.dialog.deleteItem'),
        this.$t('system.warning'),
        {
          confirmButtonText: this.$t('system.confirm'),
          cancelButtonText: this.$t('system.cancel'),
          type: 'warning'
        }
      ).then(async () => {
        await api.call('deleteMessage', {
          id: this.data.id,
          rootId: this.data.rootId
        })
        this.$message({
          type: 'success',
          message: this.$t('messages.dialog.deleteSuccess')
        })
        this.removeDialogItem(this.data.id)
        this.getDialog(this.rootId)
        this.getDialogs()
        this.getSentMessages()
      })
    }
  }
}
</script>

<style lang="scss">
@import '_assets/dialogmessage';

// stylelint-disable-next-line
.hide-header .e-dialog__new-subject {
  display: none;
}
</style>
